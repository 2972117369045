body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Arial, Helvetica, sans-serif !important;
}

.x_panel:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.table {
  margin-bottom: 0rem !important;
}

.react-bs-table-bordered {
  border: none !important;
}

.table th,
.table td {
  padding: 0.6rem !important;
  border-top: 1px solid #dee2e6 !important;
}

.table-bordered td,
.table-bordered th {
  border: none !important;
  /* border-bottom: 1px solid #dee2e6 !important; */
  height: 15px !important;
}

.table-bordered td {
  border: none !important;
}
